<template>
  <div>
    <content-holder v-if="canViewPayments" :title="'Payments'" class="p-4">
      <template #actions>
        <div class="flex items-center space-x-4 text-sm md:text-lg 2xl:text-xl">
          <h1 class="text-gray-500 font-semibold">Charts:</h1>
          <div class="flex items-center text-sm md:text-lg 2xl:text-xl">
            <span
              class="font-semibold text-gray-400"
              :class="{ 'text-red-500': !showChart }"
            >
              Off
            </span>

            <div
              class="relative inline-block w-10 mx-2 align-middle select-none"
            >
              <input
                type="checkbox"
                name="toggle"
                v-model="showChart"
                id="liveChart"
                :class="[showChart ? 'bg-green-500' : 'bg-red-500']"
                class="outline-none focus:outline-none right-4 checked:right-0 duration-200 ease-in absolute block w-6 h-6 rounded-full border-4 appearance-none cursor-pointer"
              />
              <label
                for="liveChart"
                class="block h-6 overflow-hidden bg-gray-300 rounded-full cursor-pointer"
              >
              </label>
            </div>
            <span
              class="font-semibold text-gray-400"
              :class="{ 'text-green-500': showChart }"
            >
              On
            </span>
          </div>
        </div>
      </template>

      <template #default>
        <div>
          <live-charts
            v-if="showChart"
            :pSearchPaymentData="searchPaymentData"
          ></live-charts>
          <live-payments
            @search-clicked="searchPaymentClicked"
            @clear-search="clearSearchData"
            v-else
          ></live-payments>
        </div>
      </template>
    </content-holder>
    <content-holder v-else :title="'Payments'" class="p-4">
      <unauthorized-access></unauthorized-access>
    </content-holder>
  </div>
</template>

<script>
import UnauthorizedAccess from "@/components/shared/UnauthorizedAccess.vue";
import LiveCharts from "@/components/payments/LiveCharts.vue";
import LivePayments from "@/components/payments/LivePayments.vue";
import ContentHolder from "../components/ContentHolder.vue";
import { views } from "@/helpers/login_details.js";
export default {
  //  mixins: [clickaway],
  components: {
    UnauthorizedAccess,
    ContentHolder,
    LiveCharts,
    LivePayments,
  },
  data: () => ({
    tab: "topPayers",
    canViewPayments: false,
    showChart: false,
    searchPaymentData: {},
  }),
  methods: {
    searchPaymentClicked(payload) {
      this.searchPaymentData = payload;
    },
    clearSearchData() {
      this.searchPaymentData = {};
    },
  },
  created() {
    if (views.includes("Payments")) {
      this.canViewPayments = true;
    } else {
      this.canViewPayments = false;
    }
  },
};
</script>

<style></style>
